import axios from 'axios';
// import config from './config'

export const HTTP = axios.create({
	baseURL: 'http://localhost:3000/api/',
	headers: {
		Authorization: 'Bearer {token}',
		Accept: 'application/json'
	}
})

// axios.defaults.baseURL = 'http://localhost:8080/api/'

// axios.defaults.baseURL = (env === 'development') ? 'https://localhost:8080/api' : 'http://dev.barredowlband.com/';