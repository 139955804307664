<template>
	<div style="width:100%; text-align:center;">
		<img style="max-width:35%;text-align:center;" src="http://www.barredowlband.com/img/bosb-owl-profile-logo.jpg" alt="The Barred Owl Stringband">
		<h1 style="margin:0 0 0 0;z-index:9999;padding-bottom:0;text-align:center;width:100%;display:block;">The Barred Owl Stringband</h1>
		
	</div>
</template>

<script>
	
	
</script>

<style scoped>
	/*styles*/
</style>