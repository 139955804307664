<template>
	<section class="header">
		<!-- empty -->
	</section>

	<ul>
		<li >
			<router-link class="nav-link active" aria-current="page" to="/">Home</router-link>
		</li>
		<li >
			<router-link class="nav-link active" aria-current="page" to="/show">Show</router-link>
		</li>
		<li >
			<router-link class="nav-link active" aria-current="page" to="/contact">Contact</router-link>
		</li>
	</ul>
</template>

<script>
	export default {
		name: "headerInc",
	};
</script>