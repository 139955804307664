<template>
	<div>
		<h1>Show</h1>
		<ul v-for="customer in customers" :key="customer.id">
			<li>{{customer.fname}}</li>
			<li>{{customer.lname}}</li>
			<li>{{customer.phone}}</li>
			<li>{{customer.email}}</li>
		</ul>
	</div>
</template>

<script>
	import { HTTP } from '../../../api/variables';
	
	export default {
		name: "Show",
		data() {
			return {
				customers: {}
			}
		},
		mounted(){
			this.loadCustomersTable();
		},
		methods: {
			loadCustomersTable: function(){
				HTTP.get("/events").then((res) => {
					this.customers = res.data.data;
				})
				.catch(()=>{
					console.log("Show VueJS: Something Went Wrong");
				})
			}
		}
	};
</script>