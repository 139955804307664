
<template>
	<footer class="footer py-3 bg-dark">
		<p>Say Hello: <a href="mailto:barredowlband@gmail.com" title="barredowlband@gmail.com">barredowlband@gmail.com</a></p>
	</footer>
</template>

<script>
export default {
  name: "footerInc",
};
</script>